let app = new Vue({
    el: '#app',
    mixins: [App.mixin],
    data() {
        return {
            // 聯絡買家
            idx_newest: '1',
            idx_pop: '1',
            idx_select: '1',
        }
    },
});


$(window).on('load', function() {
    const swiper_hero = new Swiper('.swiper_hero', {
        slidesPerView: 1,
        spaceBetween: 10,
        speed: 600,
        loop: true,
        navigation: {
            nextEl: '.next_hero',
            prevEl: '.prev_hero',
        },
        pagination: {
            el: '.page_hero',
            type: 'bullets',
            clickable: true
        },
    });

    const swiper_newest = new Swiper('.swiper_newest', {
        slidesPerView: 1,
        spaceBetween: 10,
        breakpoints: {
            320: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            980: {
                slidesPerView: 4,
                spaceBetween: 30
            },
            1800: {
                slidesPerView: 5,
                spaceBetween: 40
            }
        },
        navigation: {
            nextEl: '.next_newest',
            prevEl: '.prev_newest',
        },
    });
    const swiper_artist = new Swiper('.swiper_artist', {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        breakpoints: {
            480: {
                spaceBetween: 20
            },
            1800: {

                spaceBetween: 140
            }
        },
        pagination: {
            el: '.page_artist',
            type: 'bullets',
            clickable: true
        },
        navigation: {
            nextEl: '.next_artist',
            prevEl: '.prev_artist',
        },
    });


    const swiper_promo = new Swiper('.swiper_promo', {
        slidesPerView: 1,
        spaceBetween: 20,
        breakpoints: {
            740: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            1380: {
                slidesPerView: 3,
                spaceBetween: 20
            },
            1800: {
                slidesPerView: 3,
                spaceBetween: 40
            }
        },
        pagination: {
            el: '.page_promo',
            type: 'bullets',
            clickable: true
        },


    });


    const swiper_brand = new Swiper('.swiper_brand', {
        slidesPerView: 4,
        spaceBetween: 20,
        breakpoints: {
            740: {
                slidesPerView: 5,
                spaceBetween: 30
            },
            980: {
                slidesPerView: 7,
                spaceBetween: 30
            },
            1100: {
                slidesPerView: 7,
                spaceBetween: 40
            },
            1380: {
                slidesPerView: 7,
                spaceBetween: 64
            }
        },
        navigation: {
            nextEl: '.next_brand',
            prevEl: '.prev_brand',
        },
    });

    const swiper_pop = new Swiper('.swiper_pop', {
        slidesPerView: 2,
        spaceBetween: 20,
        slidesPerGroup: 2,
        breakpoints: {

            480: {
                slidesPerView: 3,
                spaceBetween: 30,
                slidesPerGroup: 3,
            },
            640: {
                slidesPerView: 4,
                slidesPerGroup: 4,
                spaceBetween: 40
            },
            1380: {
                slidesPerView: 5,
                slidesPerGroup: 5,
                spaceBetween: 40
            },
            1800: {
                slidesPerView: 6,
                slidesPerGroup: 6,
                spaceBetween: 40
            }
        },
        navigation: {
            nextEl: '.next_pop',
            prevEl: '.prev_pop',
        },
        pagination: {
            el: '.page_pop',
            type: 'bullets',
            clickable: true
        },
    });

    const swiper_flagship = new Swiper('.swiper_flagship', {
        slidesPerView: 1,
        spaceBetween: 10,
        breakpoints: {
            320: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            480: {
                slidesPerView: 3,
                spaceBetween: 30
            },
            740: {
                slidesPerView: 5,
                spaceBetween: 40
            },
            1380: {
                slidesPerView: 6,
                spaceBetween: 40
            },
            1800: {
                slidesPerView: 7,
                spaceBetween: 50
            }
        }
    });

    var goodartwiper = new Swiper(".artgallery_goodart", {
        slidesPerView: 1,
        spaceBetween: 30,
        pagination: {
            el: '.page_goodart',
            type: 'bullets',
            clickable: true
        },
        navigation: {
            nextEl: ".next_goodart", // 上一頁按鈕物件
            prevEl: ".prev_goodart", // 下一頁按鈕物件
        },
        breakpoints: {
            // when window width is >= 320px
            740: {
                slidesPerView: 2,
                spaceBetween: 25
            },
            980: {
                slidesPerView: 3,
                spaceBetween: 30
            },
            1700: {
                slidesPerView: 4,
                spaceBetween: 30
            },

        }
    });
});
